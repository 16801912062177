/* Gaya untuk select */
.select-language {
    background-color: transparent !important;
    border: none;
    color: black;
    text-align: center;
    text-decoration: none;
    display: inline-block; /* atau 'inline' */
    font-size: 16px;
    cursor: pointer;
    width: 110px; /* atau nilai spesifik seperti '100px' */
    max-width: 100%; /* atau nilai spesifik lainnya */
}


/* Gaya untuk option */
.select-language option {
    color: black;
    background-color: transparent !important;
}

/* Gaya untuk option yang dipilih */
.select-language option:checked {
    color: green;
}
